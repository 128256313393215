<template>
  <div class="postNotificationContainer">
    <el-form
      ref="mainForm"
      :model="form"
      :label-width="this.$widthRatio * 120 + 'px'"
    >
      <el-form-item prop="groupType" label="To">
        <el-select
          :disabled="!this.initData.enableToElements"
          v-model="form.groupType"
          value-key="key"
          placeholder="Please select..."
          @change="handleGroupTypeChange"
        >
          <el-option
            v-for="item in options.groupTypeList"
            :key="item"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="To..." v-if="form.groupType === 'StudentIndividual'">
        <el-select
          :disabled="!this.initData.enableToElements"
          v-model="form.recordListValue"
          multiple
          filterable
          :allow-create="false"
          default-first-option
          :reserve-keyword="false"
          :fit-input-width="true"
          style="display: block"
        >
          <el-option
            v-for="item in form.recordListValue"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="openImportStudentDialog">
          <el-icon>
            <plus />
          </el-icon>
          Import From Students
        </el-button>
      </el-form-item>
      <el-form-item label="To..." v-if="form.groupType === 'ToClassroom'">
        <el-select
          :disabled="!this.initData.enableToElements"
          v-model="form.groupId"
          style="display: block"
          placeholder="Please select..."
        >
          <el-option
            v-for="item in options.classRoomList"
            :key="item.roomId"
            :label="item.name"
            :value="item.roomId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="templateId" label="Template">
        <el-select
          v-model="form.templateId"
          value-key="key"
          @change="handleTemplateChange"
          style="display: block"
          placeholder="Please select..."
        >
          <el-option
            v-for="item in options.templateList"
            :key="item"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="subject" label="Subject">
        <el-input v-model="form.subject"></el-input>
      </el-form-item>
      <el-form-item label="Body">
        <ckeditor
          :editor="editor"
          v-model="form.body"
          :config="editorConfig"
        ></ckeditor>
      </el-form-item>
    </el-form>
    <div class="el-dialog__footer">
      <el-button v-if="this.initData.isDialogMode" @click="handleCancel">
        Cancel
      </el-button>
      <el-button type="primary" @click="saveEdit(true)">
        Save & Post
      </el-button>
      <el-button type="primary" @click="saveEdit(false)">
        Save as Draft
      </el-button>
    </div>
  </div>
  <el-dialog
    :title="dialogTitle"
    v-model="showStudentSelectionDialog"
    width="85%"
  >
    <students-selection
      :initData="{ parent: this, confirmFunction: handleImportStudents }"
    />
  </el-dialog>
</template>

<script>
import { getData, putData, postData } from "../../service/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import StudentsSelection from "./StudentsSelection.vue";

export default {
  props: {
    initData: {
      parent: Object,
      isDialogMode: false,
      handleResult: Function,
      mode: String,
      storageId: String,
      groupId: String,
      groupType: String,
      enableToElements: true,
    },
  },
  name: "postNotification",
  components: { StudentsSelection },
  data() {
    return {
      controllerUrl: "/dataStorage",
      options: {
        templateList: [],
        classRoomList: [],
        groupTypeList: [],
      },
      showStudentSelectionDialog: false,
      dialogTitle: "",
      currentTemplate: {},
      dataStorage: {},
      editor: ClassicEditor,
      form: {
        storageId: null,
        toClassroom: null,
        recordListValue: [],
        recordList: [],
        groupType: null,
        groupId: null,
        templateId: null,
        subject: null,
        body: null,
        attachment: null,
        isHTML: true,
      },
    };
  },
  created() {
    this.options.groupTypeList.push({
      key: "StudentIndividual",
      value: "To student[s]",
    });
    this.options.groupTypeList.push({
      key: "ToClassroom",
      value: "To a classroom",
    });
    this.options.groupTypeList.push({
      key: "AllStudent",
      value: "To all students",
    });
    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.dataStorage = {};
      this.dataStorage.dataType = "PostNotification";
      this.dataStorage.status = "Normal";

      if (this.initData.mode === "EDIT" && this.initData.storageId) {
        this.form.storageId = this.initData.storageId;
        this.loadData();
      }
      if (this.initData.groupType === "ToClassroom") {
        this.form.groupId = this.initData.groupId;
        this.form.groupType = this.initData.groupType;
        this.loadAllClassRooms();
      }
      this.loadAllTemplates();
    },
    loadData() {
      getData(`/${this.controllerUrl}/${this.form.storageId}`).then((res) => {
        if (res.result && res.code === "200") {
          this.dataStorage = res.result;
          this.form = JSON.parse(res.result.data);
          this.form.storageId = res.result.storageId;
        } else {
          this.$message.error(
            "Load storage failed, error message: " + res.message
          );
        }
      });
    },
    async loadAllClassRooms() {
      let res = await getData("classRoom/getAllClassRoomIdAndNameForReceipt");
      if (res) {
        this.options.classRoomList = res.result;
        // sort classrooms via grade asc, term desc
        this.options.classRoomList.sort((x, y) => {
          return (
            (x.activeStatus - y.activeStatus) * 10000 +
            (x.gradeValue - y.gradeValue) * 1000 +
            (y.termValue - x.termValue) * 100 +
            x.name.localeCompare(y.name)
          );
        });
      }
    },
    loadAllTemplates() {
      getData("/generalTemplate/Notification/getAllTemplateIdAndName").then(
        (res) => {
          if (res.result && res.code === "200") {
            this.options.templateList = res.result;
          } else {
            this.$message.error(
              "Load templates failed, error message: " + res.message
            );
          }
        }
      );
    },
    loadTemplate() {
      getData(`/generalTemplate/${this.form.templateId}`).then((res) => {
        if (res.result && res.code === "200") {
          let template = res.result;
          this.form.subject = template.subject;
          this.form.body = template.content;
          this.form.isHTML = template.enableHTML;
          this.currentTemplate = template;
        } else {
          this.$message.error(
            "Load template failed, error message: " + res.message
          );
        }
      });
    },
    openImportStudentDialog() {
      this.showStudentSelectionDialog = true;
    },
    closeImportStudentDialog() {
      this.showStudentSelectionDialog = false;
    },
    handleImportStudents(studentList) {
      try {
        let recordListValueArr = this.form.recordListValue || [];
        let recordList = this.form.recordList || [];
        console.log("handleImportStudents", studentList);
        if (studentList && studentList.length > 0) {
          for (let i = 0; i < studentList.length; i++) {
            let student = studentList[i];
            if (recordListValueArr.indexOf(student.studentId) >= 0) continue;
            recordListValueArr.push(student.studentId);
            if (
              recordList.findIndex((e) => e.receiverId === student.studentId) <
              0
            ) {
              recordList.push({
                receiverId: student.studentId,
                receiverName: student.username,
                status: "Normal",
              });
            }
          }
          this.form.recordListValue = recordListValueArr;
          this.form.recordList = recordList;
        }
      } catch {
        // ignore
      } finally {
        this.closeImportStudentDialog();
      }
    },
    async saveEdit(posting) {
      if (posting) {
        await this.postNotification();
        this.form.sendingResponse = {};
      }

      this.dataStorage.title = this.form.subject;
      this.dataStorage.summary = this.form.body;
      this.dataStorage.data = JSON.stringify(this.form);

      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (posting) {
            this.dataStorage.status = "Sent";
          } else {
            this.dataStorage.status = "Draft";
          }
          if (
            this.dataStorage.storageId &&
            this.dataStorage.storageId.length === 22
          ) {
            putData(
              this.controllerUrl,
              this.dataStorage.storageId,
              this.dataStorage
            ).then((res) => {
              if (res.result && res.code === "200") {
                if (this.initData.isDialogMode) {
                  this.initData.handleResult.call(
                    this.initData.parent,
                    "UPDATE",
                    true,
                    this.dataStorage
                  );
                }
                if (!posting) {
                  this.$message.success("Update successfully");
                }
              } else {
                if (this.initData.isDialogMode) {
                  this.initData.handleResult.call(
                    this.initData.parent,
                    "UPDATE",
                    false
                  );
                }
                this.$message.error(
                  "Update failed, error message: " + res.message
                );
              }
            });
          } else {
            postData(this.controllerUrl, this.dataStorage).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                if (this.initData.isDialogMode) {
                  this.initData.handleResult.call(
                    this.initData.parent,
                    "CREATE",
                    true,
                    this.dataStorage
                  );
                }
                if (!posting) {
                  this.$message.success("Create successfully");
                }
                this.form = res.result;
              } else {
                if (this.initData.isDialogMode) {
                  this.initData.handleResult.call(
                    this.initData.parent,
                    "CREATE",
                    false
                  );
                }
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    async postNotification() {
      console.log("postNotification", this.form);
      let res = await postData("/notification/post", this.form);
      if (res.result && res.code === "200") {
        this.$message.success("Post successfully");
      } else {
        this.$message.error("Post failed, error message: " + res.message);
      }
      return null;
    },
    handleTemplateChange(templateId) {
      console.log("handleTemplateChange", arguments);
      this.form.templateId = templateId;
      this.loadTemplate();
    },
    handleGroupTypeChange(groupType) {
      if (groupType === "ToClassroom" && !this.options.classRoomList.length) {
        this.loadAllClassRooms();
      }
    },
    handleCancel() {
      this.initData.handleResult.call(this.initData.parent, "CANCEL");
    },
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 200px;
}
.el-select__input {
  min-width: auto;
  max-width: auto;
  width: 100%;
}
</style>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
